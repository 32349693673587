// Section1.js

import Calculator from "./Calculator";

function Section1() {
    return (
    <section id="thevatcalculator" className="section1" >
        <div className="section1-container">
            <div className="column-container cols2">
                <div className="column">
                    <h1>Free VAT Calculator</h1>
                    <p>
                        Welcome to our free VAT Calculator allowing you to quickly work out VAT amounts added to - or removed from a NET or GROSS value.
                    </p>
                    <p>
                        The default VAT rate is set to 20% (the current UK VAT rate). This can easily be changed to calculate any percentage.
                    </p>
                    <p>
                        If you need to know 'How to calculate VAT yourself?', See our guide below.
                    </p>
                    <p className={"mobile-hide"}>
                        In the UK, most businesses are required to charge Value Added Tax (VAT) on their goods and services if they meet certain criteria set by HM Revenue & Customs (HMRC).
                    </p>
                    <p className={"mobile-hide"}>
                        The current threshold for VAT registration is £85,000 of taxable turnover in a 12-month period (known as the VAT threshold). Businesses can also voluntarily register for VAT if their turnover is below this threshold.
                    </p>
                    <p className={"mobile-hide"}>
                        The threshold reached £85,000 in 2017-18 and on current policy will be frozen at that level for eight years, until March 2026.
                    </p>

                </div>
                <div className="column center">
                    <Calculator/>
                </div>

            </div>
        </div>
    </section>
    );
}

export default Section1;