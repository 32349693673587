import {useNavigation} from "./NavigationProvider";
import BackToTop from "./BackToTop";

const Section5 = () => {

    const { scrollToSection } = useNavigation();

    return (
        <section id="currentvatrates" className="section5">
            <div className="section5-container">
                <div className="column-container">
                    <div className="column">

                        <BackToTop sx={{border: '1px rgba(0,0,0,.2) solid', marginTop: '60px'}}/>

                        <h1>Current VAT rates in Europe</h1>
                        <p>
                            VAT rates in Europe can vary significantly from country to country and may also depend on the type of goods or services being sold. Each country is responsible for setting its own rates. We recommended that you check the
                            latest rates with your local VAT office.</p>
                        
                        <p>Current Standard VAT rates in Europe are as follows:</p>

                        <div className="column-container cols2">
                            <div className="column grow">
                                    <table className={'vat-table'}>
                                        <tbody>
                                        <tr>
                                            <th>Country</th>
                                            <th>Rate (%)</th>
                                        </tr>
                                        <tr>
                                            <td>Austria (AT)</td>
                                            <td>20</td>
                                        </tr>
                                        <tr>
                                            <td>Belgium (BE)</td>
                                            <td>21</td>
                                        </tr>
                                        <tr>
                                            <td>Bulgaria (BG)</td>
                                            <td>20</td>
                                        </tr>
                                        <tr>
                                            <td>Croatia (HR)</td>
                                            <td>25</td>
                                        </tr>
                                        <tr>
                                            <td>Cyprus (CY)</td>
                                            <td>19</td>
                                        </tr>
                                        <tr>
                                            <td>Czech Republic (CZ)</td>
                                            <td>21</td>
                                        </tr>
                                        <tr>
                                            <td>Denmark (DK)</td>
                                            <td>25</td>
                                        </tr>
                                        <tr>
                                            <td>Estonia (EE)</td>
                                            <td>22</td>
                                        </tr>
                                        <tr>
                                            <td>Finland (FI)</td>
                                            <td>24</td>
                                        </tr>
                                        <tr>
                                            <td>France (FR)</td>
                                            <td>20</td>
                                        </tr>
                                        <tr>
                                            <td>Germany (DE)</td>
                                            <td>19</td>
                                        </tr>
                                        <tr>
                                            <td>Greece (GR)</td>
                                            <td>24</td>
                                        </tr>
                                        <tr>
                                            <td>Hungary (HU)</td>
                                            <td>27</td>
                                        </tr>
                                        <tr>
                                            <td>Iceland (IS)</td>
                                            <td>24</td>
                                        </tr>
                                        <tr>
                                            <td>Ireland (IE)</td>
                                            <td>23</td>
                                        </tr>
                                        <tr>
                                            <td>Italy (IT)</td>
                                            <td>22</td>
                                        </tr>

                                        </tbody>
                                    </table>

                            </div>
                            <div className="column grow">
                                <table className={'vat-table'}>
                                        <tbody>
                                        <tr>
                                            <th>Country</th>
                                            <th>Rate (%)</th>
                                        </tr>
                                        <tr>
                                            <td>Latvia (LV)</td>
                                            <td>21</td>
                                        </tr>
                                        <tr>
                                            <td>Lithuania (LT)</td>
                                            <td>21</td>
                                        </tr>
                                        <tr>
                                            <td>Luxembourg (LU)</td>
                                            <td>17</td>
                                        </tr>
                                        <tr>
                                            <td>Malta (MT)</td>
                                            <td>18</td>
                                        </tr>
                                        <tr>
                                            <td>Netherlands (NL)</td>
                                            <td>21</td>
                                        </tr>
                                        <tr>
                                            <td>Norway (NO)</td>
                                            <td>25</td>
                                        </tr>
                                        <tr>
                                            <td>Poland (PL)</td>
                                            <td>23</td>
                                        </tr>
                                        <tr>
                                            <td>Portugal (PT)</td>
                                            <td>23</td>
                                        </tr>
                                        <tr>
                                            <td>Romania (RO)</td>
                                            <td>19</td>
                                        </tr>
                                        <tr>
                                            <td>Slovakia (SK)</td>
                                            <td>20</td>
                                        </tr>
                                        <tr>
                                            <td>Slovenia (SI)</td>
                                            <td>22</td>
                                        </tr>
                                        <tr>
                                            <td>Spain (ES)</td>
                                            <td>21</td>
                                        </tr>
                                        <tr>
                                            <td>Sweden (SE)</td>
                                            <td>25</td>
                                        </tr>
                                        <tr>
                                            <td>Switzerland (CH)</td>
                                            <td>8.1</td>
                                        </tr>
                                        <tr>
                                            <td>Turkey (TR)</td>
                                            <td>20</td>
                                        </tr>
                                        <tr>
                                            <td>United Kingdom (GB)</td>
                                            <td>20</td>
                                        </tr>
                                        </tbody>
                                    </table>
                            </div>
                        </div>

                        <p className={'source'}>Source: https://europa.eu/youreurope/business/taxation/vat/vat-rules-rates/index_en.htm#inline-nav-7</p>
                        
                    </div>
                </div>
            </div>
        </section>
    );
}

export default Section5;