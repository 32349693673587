import {useNavigation} from "./NavigationProvider";
import BackToTop from "./BackToTop";

const Section4 = () => {

    const { scrollToSection } = useNavigation();

    return (
        <section id="whydoesvatexist" className="section4">
            <div className="column-container">
                <div className="column">

                    <BackToTop sx={{border: '1px rgba(0,0,0,.2) solid'}}/>

                    <h1>Why does VAT exist</h1>
                    <p>Value-Added Tax (VAT) exists primarily as a method of revenue generation for governments. Unlike income taxes, which are
                        based on earnings, VAT is based on consumption and is collected throughout the supply chain,
                        making it a steady and reliable source of government revenue.</p>
                </div>
            </div>
        </section>
    );
}

export default Section4;