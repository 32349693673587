import { createContext, useContext } from 'react';

// Create a Context
const NavigationContext = createContext();

// Context Provider Component
export const NavigationProvider = ({ children }) => {
    const scrollToSection = (sectionId) => {
        if(!sectionId || sectionId===""){
            sectionId = 'thevatcalculator';
        }
        //console.log('scrollToSection:', sectionId);
        const section = document.getElementById(sectionId);
        if (section) {
            section.scrollIntoView({ behavior: 'smooth', block: 'start' });
            if (sectionId === 'thevatcalculator') {
                window.history.pushState({ id: sectionId }, '', '/');
            } else {
                window.history.pushState({ id: sectionId }, '', `#${sectionId}`);
            }
        }
    };

    return (
        <NavigationContext.Provider value={{ scrollToSection }}>
            {children}
        </NavigationContext.Provider>
    );
};

// Hook to use the navigation context
export const useNavigation = () => useContext(NavigationContext);