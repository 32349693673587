import "../css/App.scss";
// import React from 'react';
// import { BrowserRouter as Router, Route, Routes, Switch, Link } from "react-router-dom";
import { ThemeProvider, createTheme } from '@mui/material/styles';
import {NavigationProvider} from "./NavigationProvider";

import Header from "./Header";
import Section1 from "./Section1";
import Section2 from "./Section2";
import Section3 from "./Section3";
import Section4 from "./Section4";
import Section5 from "./Section5";
import Footer from "./Footer";


const appTheme = createTheme({
    components: {
        // Define styles for MUI Button
        MuiFab: {
            styleOverrides: {
                root: {
                    marginLeft: 'calc(100% - 40px)',
                    marginTop: '5px',
                    color: '#2f3b72',
                    backgroundColor: '#fff',
                    // border: '1px rgba(47,59,114,.3) solid',
                    '&:hover': {
                        color: 'white',
                        backgroundColor: '#2f3b72',
                    },
                },
            },
        },
    },
});


const App = () => {


    return (
        <ThemeProvider theme={appTheme}>
            <div className="app">
                {/*<Router>*/}
                    <NavigationProvider>
                        <Header/>
                        {/*<Navigation />*/}
                        {/*<ScrollTo />*/}
                        <Section1/>
                        <Section2/>
                        <Section3/>
                        <Section4/>
                        <Section5/>
                        <Footer/>
                    </NavigationProvider>
                {/*</Router>*/}
            </div>
        </ThemeProvider>
    );
};



export default App;
