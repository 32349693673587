
function Footer() {
    return (
        <footer>
            <section id="footer" className="">
                <div className="footer-container">
                    <div className="column-container">
                        <div className="column">
                            <p>
                                Copyright 2024
                            </p>
                            <p>
                                {/*Want more Calculators? Tell us which type...*/}
                                thevatcalculators.com
                            </p>
                            
                        </div>
                    </div>
                </div>
            </section>
        </footer>

    );
}

export default Footer;