import {useNavigation} from "./NavigationProvider";
import BackToTop from "./BackToTop";

const Section2 = () => {

    const { scrollToSection } = useNavigation();

    return (
        <section id="whatisvat" className="section2">
            <div className="column-container">
                <div className="column">

                    <BackToTop/>

                    <h1>What is VAT?</h1>

                    <p>VAT stands for Value-Added Tax. It is a type of consumption tax that is placed on a product
                        whenever value is added at each stage of the supply chain, from production to the point of sale.
                        The amount of VAT that the user pays is on the cost of the product, less any of the costs of
                        materials used in the product that have already been taxed.</p>

                    <p>
                        VAT is designed to be paid by the end consumer. Businesses collect and account for the tax on
                        behalf of the government and remit the collected tax after deducting the VAT they have paid on
                        their purchases related to those sales. This mechanism ensures that the tax is neutral
                        regardless of how many transactions are involved.
                    </p>

                    <p>Key characteristics of VAT include:</p>

                    <ul>
                        <li><strong>Multi-Stage:</strong> VAT is charged at every step of the supply chain. Manufacturers, wholesalers,
                            and
                            retailers all pay VAT on their purchases and collect VAT on their sales.
                        </li>

                        <li><strong>Revenue for Governments:</strong> VAT is one of the most common types of indirect taxes and is a
                            significant source of revenue for governments.
                        </li>

                        <li><strong>Price Inclusion:</strong> Typically, the final price of goods and services includes VAT; hence, consumers
                            are often unaware of the VAT component in the price they pay.</li>

                        <li><strong>Credit Mechanism:</strong> Businesses can reduce their VAT liability by the amount of tax they have
                            already paid to other businesses in the supply chain. This is often referred to as "input tax
                            credit."</li>

                        <li><strong>Variability:</strong> The rate of VAT can vary depending on the type of product or service. Some items
                            might be exempt from VAT, while others may be taxed at higher or reduced rates.</li>

                        <li><strong>Global Use:</strong> VAT is used in many countries around the world, although the specific rules and rates
                            can vary significantly. In the United States, there is no federal VAT; instead, sales tax is
                            more common, which is a single-stage tax only charged at the point of sale to the final
                            consumer.</li>
                    </ul>

                </div>
            </div>
        </section>
    );
}

export default Section2;