import {useState, useEffect, useRef} from "react";
import { ThemeProvider, createTheme } from '@mui/material/styles';
import TextField from '@mui/material/TextField';
import {Button, ButtonGroup, ToggleButton, Box} from '@mui/material';
import IconButton from '@mui/material/IconButton';
import ToggleBtn from "./ToggleBtn";
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import Tooltip from '@mui/material/Tooltip';
import InputAdornment from '@mui/material/InputAdornment';
import Stack from '@mui/material/Stack';

import "../css/Calculator.scss";


function Calculator() {

    const [vatRadio, setVatRadio] = useState("addvat");
    const [incExc, setIncExc] = useState("Incl VAT");
    const [calcDisabled, setCalcDisabled] = useState(true);
    const [startValue, setStartValue] = useState("");
    const [rate, setRate] = useState("20");
    const [vatType, setVatType] = useState("add");
    const [vatAmount, setVatAmount] = useState(0);
    const vatAmountRef = useRef();
    vatAmountRef.current = 0;
    const [totalAmount, setTotalAmount] = useState(0);
    //
    const anim1 = useRef(null);
    const anim2 = useRef(null);

    const [throb, setThrob] = useState(false);

    // MUI overrides
    const calculatorTheme = createTheme({

        components: {
            // Define styles for MUI Button
            MuiButton: {
                styleOverrides: {
                    root: {
                        color: 'white',
                        backgroundColor: '#2f3b72',
                        // '&:hover': {
                        //     color: 'white',
                        //     backgroundColor: '#2f3b72',
                        // },
                    },
                },
            },
            // Apply the same styles to MUI ToggleButton
            MuiToggleButton: {
                styleOverrides: {
                    root: {
                        // color: 'white',
                        // backgroundColor: '#17369f',
                        // '&:hover': {
                        //     backgroundColor: 'darkblue',
                        // },

                        '&.MuiButtonBase-root': {
                            // color: '#505050',
                            color: 'black',
                            backgroundColor: '#fff',
                        },
                        '&.MuiButtonBase-root:hover': {
                            // color: 'black',
                            border: '1px solid #2f3b72'
                            //backgroundColor: 'rgba(23,54,159,0.07)',
                            // backgroundColor: 'white',
                        },
                        '&.Mui-selected': {
                            color: 'white',
                            backgroundColor: '#2f3b72',
                        },
                        '&.Mui-selected:hover': {
                            color: 'white',
                            backgroundColor: '#2f3b72',
                        },

                    },
                },
            },
        },
    });

    const handleThrob = () => {
        // Trigger the throb effect
        setThrob(true);
        // Reset the effect to allow for re-triggering
        setTimeout(() =>
            setThrob(false), 1000); // Duration of the throb animation plus any delay
    };


    useEffect(() => {
        //console.log('useEffect:', {startValue}, {rate});

        if(startValue && rate) {
            //console.log('both have value');
            setCalcDisabled(false);

        }else{
            //console.log('one has NO value');
            setCalcDisabled(true);
            //setVatType("null");
        }

    }, [startValue, rate]);

    useEffect(() => {
       //handleCalculate();
        setCalcDisabled(false);
        setVatAmount(0);
        setTotalAmount(0);
    }, [vatType]);

    function validate(s) { // Only allow numbers and point
        let rgx = /^[0-9]*\.?[0-9]*$/;
        return s.match(rgx);
    }

    function handleUserInputChange(e) {
        // console.log('handleUserInputChange:', e.target.value);
        validate(e.target.value) ? setStartValue(e.target.value) : null;
        setVatAmount(0);
        setTotalAmount(0);
        //setVatType("null");
    }

    function handleRateChange(e) {
        // console.log('handleRateChange:', e.target.value);
        validate(e.target.value) ? setRate(e.target.value) : null;
        setVatAmount(0);
        setTotalAmount(0);
        //setVatType("null");
    }

    function handleCalculate() {
        console.log('handleCalculate:', startValue, rate);

        let tempVatAmount = (startValue / 100) * rate;
        let tempTotalAmount = Number(startValue) + Number(tempVatAmount);
        if(vatType==="remove") {
            //tempTotalAmount = Number(startValue) - Number(tempVatAmount);
            tempTotalAmount = findValuePreVAT(startValue, rate);
            tempVatAmount = (startValue - tempTotalAmount);
        }
        setVatAmount(formatCurrency(tempVatAmount));
        setTotalAmount(formatCurrency(tempTotalAmount));
        handleThrob();
        //setTimeout(() => setVatAmount(formatCurrency(tempVatAmount)), 100);
        setCalcDisabled(true);
    }

    function findValuePreVAT(finalPrice, percentageIncrease) {
        console.log('findValuePreVAT:', finalPrice, percentageIncrease);
        return finalPrice / (1 + percentageIncrease / 100);
    }

    function handleBtnVat(a) {
        console.log('handleBtnVat:', a);
    }

    function handleSwitchChange(e){
        console.log('handleSwitchChange:', e.target.checked);
    }

    function formatCurrency(number) {
        return number.toFixed(2);
    }

    async function copyValue(value) {
        console.log('async copyValue:', value);
        try {
            await navigator.clipboard.writeText(value);
            // alert('Text copied to clipboard');
        } catch (err) {
            alert('Failed to copy text: ', err);
        }
    }

    return (
        <ThemeProvider theme={calculatorTheme}>
            <div className='calculator'>
                {/*<div>*/}
                    {/*<h1>The VAT Calculator</h1>*/}
                    <div className='calculator-instruction'>
                        To calculate VAT, enter start value, select 'Add VAT' or 'Remove' VAT then 'Calculate'. Copy results into your invoices.
                    </div>
                {/*</div>*/}


                {/*<div className="">{vatType === "remove" ? 'Value Including VAT' : 'Value Excluding Vat'} :</div>*/}
                {/*<div>*/}
                    <Box
                        component="form"
                        // sx={{
                        //     '& .MuiTextField-root': { m: 1, width: '25ch' },
                        //     '& .MuiInputBase-input': {
                        //         fontSize: '1.5rem', // Set the font size for the input text
                        //     },
                        // }}
                        sx={{
                            '& .MuiTextField-root': { mt: '16px', width: '100%' },
                            '& .MuiInputBase-input, & .MuiTypography-root': {
                                fontSize: '22px',
                                lineHeight: '2'
                            },
                            '& .MuiInputBase-input':{
                                height: '22px'
                            },
                            '& .MuiButtonBase-root':{
                                mt: '16px',
                                // height: '48px'
                            },
                            '& .MuiStack-root':{
                                width : '280px'
                            },
                        width : '240px',
                            // border : '1px solid',
                        }}
                        noValidate
                        autoComplete="off"
                    >
                        {/*<TextField label={vatType === "remove" ? 'Start Value (Including VAT)' : 'Start Value (Excluding VAT)'} variant="outlined" value={startValue} InputLabelProps={{*/}
                        {/*    shrink: true,*/}
                        {/*}} onChange={handleUserInputChange}/>*/}

                        <TextField label={vatType === "remove" ? 'Start Value (Including VAT)' : 'Start Value (Excluding VAT)'} variant="outlined" value={startValue} InputLabelProps={{
                            shrink: true,
                        }} InputProps={{
                            startAdornment: (
                                <InputAdornment position="start">
                                    £
                                </InputAdornment>
                            ),
                        }} onChange={handleUserInputChange}/>
                        {/*<TextField label="Value Including Vat" variant="outlined" value={startValue} onChange={handleUserInputChange}/>*/}

                        <TextField label="VAT rate" variant="outlined" value={rate} onChange={handleRateChange} InputProps={{
                            endAdornment: <InputAdornment position="end">%</InputAdornment>,
                        }}/>

                        <ToggleBtn vatType={vatType} setVatType={setVatType} setCalcDisabled={setCalcDisabled} calcDisabled={calcDisabled} />

                        <Button fullWidth={true} sx={{
                            height: 48,
                        }} variant="contained" disabled={calcDisabled} size="large" onClick={handleCalculate}>Calculate</Button>

                        <Stack direction="row" alignItems="center">
                        <TextField className={`input ${throb ? 'throb' : ''}`} label="VAT amount" variant="filled" value={vatAmount} />

                            <Tooltip title="Copy" PopperProps={{
                                modifiers: [
                                    {
                                        name: "offset",
                                        options: {
                                            offset: [6, -10],
                                        },
                                    },
                                ],
                            }}>
                                <IconButton sx={{
                                    height: 34, // You can use numeric values which translate to pixel values
                                    width: 34, // Makes the IconButton square
                                    ml: '4px',
                                    // '& .MuiButtonBase-root':{
                                    //     height: '20px !important'
                                    // },
                                }} size={"small"} onClick={() => copyValue(vatAmount)}><ContentCopyIcon fontSize="small" /></IconButton>
                        </Tooltip>
                        </Stack>

                        {/*<TextField className={`input ${throb ? 'throb-delay' : ''}`} onAnimationEnd={() => setThrob(false)} label={vatType === "add" ? 'Start Value Including Vat' : 'Start Value Excluding Vat'} variant="filled" value={totalAmount} />*/}

                        {/*<Tooltip title="Copy">*/}
                        {/*    <IconButton aria-label="Copy" size="small" onClick={() => copyValue(totalAmount)}><ContentCopyIcon fontSize="small" /></IconButton>*/}
                        {/*</Tooltip>*/}

                        <Stack direction="row" alignItems="center">
                            <TextField className={`input ${throb ? 'throb-delay' : ''}`} onAnimationEnd={() => setThrob(false)} label={vatType === "add" ? 'Start Value Including Vat' : 'Start Value Excluding Vat'} variant="filled" value={totalAmount} />

                            <Tooltip title="Copy" PopperProps={{
                                modifiers: [
                                    {
                                        name: "offset",
                                        options: {
                                            offset: [6, -10],
                                        },
                                    },
                                ],
                            }}>
                                <IconButton sx={{
                                    height: 34, // You can use numeric values which translate to pixel values
                                    width: 34, // Makes the IconButton square
                                    ml: '4px',
                                    // '& .MuiButtonBase-root':{
                                    //     height: '20px !important'
                                    // },
                                }} size={"small"} aria-label="Copy" onClick={() => copyValue(totalAmount)}><ContentCopyIcon fontSize={"small"}/></IconButton>
                            </Tooltip>
                        </Stack>

                    </Box>

            </div>
        </ThemeProvider>
    );

}


export default Calculator;