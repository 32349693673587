import React from "react";
import {useState} from "react";
import { styled } from '@mui/material/styles';
import ToggleButton from '@mui/material/ToggleButton';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';
import { ThemeProvider } from '@mui/material/styles';

function ToggleBtn(props) {

    const ToggleBtn = styled(ToggleButton)(({ selectedColor }) => ({
        // '&.MuiButtonBase-root': {
        //     color: 'black',
        //     backgroundColor: '#fff',
        // },
        // '&.Mui-selected': {
        //     color: 'white',
        //     backgroundColor: '#17369f',
        // },
        // '&.Mui-selected:hover': {
        //     color: 'white',
        //     backgroundColor: '#151f41',
        // },
    }));

    const handleChange = (e) => {
        console.log('handleChange:', e.target.value);
        props.setVatType(e.target.value);
    };

    return (
            <ToggleButtonGroup
                variant="contained"
                color="primary"
                fullWidth={true}
                value={props.vatType}
                exclusive
                onChange={handleChange}
                aria-label="Toggle VAT Add, Remove"
                // disabled={props.calcDisabled}
            >
                <ToggleBtn value="add">ADD VAT</ToggleBtn>
                <ToggleBtn value="remove">REMOVE VAT</ToggleBtn>
            </ToggleButtonGroup>
    );
}

export default ToggleBtn;
