import { Fragment, useState } from 'react';
import "../css/Header.scss";
import { useTheme } from '@mui/material/styles';
import {Button} from "@mui/material";

import { IconButton, Drawer, List, ListItem, Divider } from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';
import CloseIcon from '@mui/icons-material/Close';

import { ThemeProvider, createTheme } from '@mui/material/styles';
// import { Link } from 'react-router-dom';
import {useNavigation} from "./NavigationProvider";


const menuArray = [{txt:'The VAT Calculator', link:'thevatcalculator'}, {txt:'What is VAT?', link:'whatisvat'}, {txt:'How to Calculate VAT', link:'howtocalculatevat'}, {txt:'Current VAT rates', link:'currentvatrates'}];

const themeHeader = createTheme({

    components: {
        // Define styles for MUI Button
        MuiButton: {
            styleOverrides: {
                root: {
                    fontSize: '13px',
                    fontFamily: 'Roboto Slab, serif',
                    fontWeight: '400',
                    lineHeight: '16px',
                    color: 'white',
                    backgroundColor: 'none',
                    '&:hover': {
                        color: 'white',
                        backgroundColor: '#2f3b72',
                        // backgroundColor: 'rgba(255, 255, 255, 0.2)', // 10% white (light gray)
                        // border: '1px solid white',
                    },
                },
            },
        },

        // Targeting the Drawer's root
        MuiDrawer: {
            styleOverrides: {
                root: {
                    '& .MuiList-root': {
                        paddingTop: '0',
                    },
                    '& .MuiPaper-root': {
                        backgroundColor: '#10173a', // Normal state background color
                        // clipPath: 'polygon(0 0, 100% 0%, 100% 200px, 0 200px)',
                    },
                    // Applying styles to all Buttons within the Drawer
                    '& .MuiListItem-root': {
                        padding: '0',
                    },
                    '& .MuiButton-root': {
                        // backgroundColor: '#2f3b72', // Normal state background color
                        // color: 'white', // Text color
                        backgroundColor: 'white', // Normal state background color
                        color: '#2f3b72', // Text color
                        width: '100%',
                        padding: '12px 8px',
                        justifyContent: 'left',
                        borderRadius: '0',
                        '&:hover': {
                            // backgroundColor: 'darkblue', // Hover state background color
                            backgroundColor: '#2f3b72',
                            color: 'white',
                        },
                        '&:first-child': {
                            justifyContent: 'right',
                        }
                    },
                },
            },
        },
    },
});


function Header() {
    const { scrollToSection } = useNavigation();
    const [drawerOpen, setDrawerOpen] = useState(false);
    const [scrollbarWidth, setScrollbarWidth] = useState(0);

    const appBarHeight = 0; // Adjust this value based on your AppBar's height
    const theme = useTheme();

    const toggleDrawer = (open) => (event) => {
        if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
            return;
        }
        setDrawerOpen(open);
    };

    const list = () => (
        <div
            role="presentation"
            onClick={toggleDrawer(false)}
            onKeyDown={toggleDrawer(false)}
        >
            <List>
                <ListItem>
                    <Button>
                            <CloseIcon fontSize="large" sx={{ justifyContent: 'right' }}/>
                    </Button>
                </ListItem>
                <Divider />

                {menuArray.map((obj, index) => (
                    <Fragment key={index}>
                        {/*<Button component={Link} to={obj.link}>{obj.txt}</Button>*/}
                        <Button key={index} onClick={() => scrollToSection(obj.link)}>{obj.txt}</Button>
                        <Divider />
                    </Fragment>
                ))}

            </List>
        </div>
    );

    return (
        <ThemeProvider theme={themeHeader}>
        <header>

            <div className="left-item"> <img src="images/logo.png" height="30px" alt="Free VAT Calculator logo" /></div>
            <div className="right-items">
                {menuArray.map((obj, index) => (
                    // <Button component={Link} to={obj.link} variant="text" className="menuItem" key={index}>T-{obj.txt}</Button>
                    <Button className="menuItem" key={index} onClick={() => scrollToSection(obj.link)}>{obj.txt}</Button>
                ))}
                <div className="menuBtn">
                    <IconButton sx={{ marginRight: 0 }}
                        edge="end"
                        color="inherit"
                        aria-label="menu"
                        onClick={toggleDrawer(true)}
                    >
                        <MenuIcon fontSize="large"/>
                    </IconButton>
                </div>

            </div>

            <Drawer

                anchor="right"
                open={drawerOpen}
                onClose={toggleDrawer(false)}
            >
                {list()}
            </Drawer>
        </header>
        </ThemeProvider>
    );
}


export default Header;