import Tooltip from "@mui/material/Tooltip";
import Fab from "@mui/material/Fab";
import UpIcon from "@mui/icons-material/KeyboardArrowUp";
import {useNavigation} from "./NavigationProvider";

const BackToTop = (props) => {

    //console.log('props:', props);

    const { scrollToSection } = useNavigation();

    return (
        <Tooltip title="Back to top" PopperProps={{
            modifiers: [
                {
                    name: "offset",
                    options: {
                        offset: [0, -10],
                    },
                },
            ],
        }}>

            <Fab
                className="fab"
                size="small"
                aria-label="Back to top of page"
                onClick={() => scrollToSection()}
                sx={props.sx}
            >
                <UpIcon/>
            </Fab>

        </Tooltip> 
    );
};

export default BackToTop;