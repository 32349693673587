import {useNavigation} from "./NavigationProvider";
import BackToTop from "./BackToTop";

const Section3 = () => {

    const { scrollToSection } = useNavigation();

    return (
        <section id="howtocalculatevat" className="section3">
            <div className="section3-container">
                <div className="column-container">
                    <div className="column">

                        <BackToTop />

                        <h1>How to Calculate VAT</h1>
                        <p>
                            When you sell goods or services, you need to work out the total price including VAT (the ‘VAT-inclusive price’) .
                        </p>
                        <p>
                            You may also need to work out how much VAT you’ve paid on goods you've purchased, for reclaiming VAT for instance. You do this by working out what the price of the goods would be without VAT (the VAT-exclusive price).
                        </p>

                        <h2>How to Calculate a VAT-inclusive amount</h2>
                        <p>
                            To work out a price that <strong>includes</strong> the standard rate of VAT (currently 20%), multiply the price excluding VAT by 1.2.
                        </p>
                        <p>
                            <strong>Example:</strong> You’re selling a service for £80 and need to add 20% VAT to get the price including VAT.
                        </p>

                        <p className={'handwritten'}>80 x 1.2 = 96</p>

                        <p><strong>£80 including VAT is £96.</strong></p>
                        <p><strong>VAT amount:</strong> 96 - 80 = £16.</p>

                        <h2>How to Calculate a VAT-exclusive amount</h2>
                        <p>
                            To work out a price that <strong>excludes</strong> the standard rate of VAT (currently 20%), <strong>divide</strong> the price including VAT by 1.2.
                        </p>
                        <p>
                            <strong>Example:</strong> You purchased an item and the total price <strong>including</strong> 20% VAT was £198.
                        </p>

                        <p className={'handwritten'}>198 ÷ 1.20 = 165</p>

                        <p><strong>£198 excluding VAT is 165.</strong></p>

                        <p><strong>VAT amount:</strong> 198 - 165 = £33.</p>
                    </div>
                </div>
            </div>
        </section>
    );
}

export default Section3;